/**
 * @param {HTMLElement} element
 */
export function Video(element) {
	let embedCode = element.dataset.videoEmbedCode;

	if (embedCode) {
		element.addEventListener('click', onClick);
	}

	function onClick() {
		element.removeEventListener('click', onClick);

		let embedTarget = element.querySelector('[data-video-target="embed"]');

		if (!embedTarget) {
			return;
		}

		element.addEventListener('load', () => console.log('load'));

		embedTarget.innerHTML = embedCode || '';
	}
}
