import playSvg from '@src/icons/play.svg?raw';
import pauseSvg from '@src/icons/pause.svg?raw';
import { createElement } from '../utils/dom';

const PAUSED_CLASS = 'is-paused';
const PLAYING_CLASS = 'is-playing';

/**
 * @param {HTMLButtonElement} element
 */
export function PlayButton(element) {
	if (!(element instanceof HTMLButtonElement)) {
		return;
	}

	let video = document.getElementById(element.dataset.target);

	element.classList.remove('hidden');
	element.addEventListener('click', togglePlay);

	video.addEventListener('click', togglePlay);
	video.addEventListener('play', checkStatus);
	video.addEventListener('pause', checkStatus);

	checkStatus();

	function checkStatus() {
		let playing = !video.paused;

		element.classList.toggle(PAUSED_CLASS, !playing);
		element.classList.toggle(PLAYING_CLASS, playing);
		element.innerHTML = playing ? pauseSvg : playSvg;

		let label = createElement('span', {
			class: 'visually-hidden',
		});

		label.textContent = playing ? 'Pause' : 'Play';

		element.append(label);
	}

	async function togglePlay() {
		if (!video.paused) {
			video?.pause();
			return;
		}

		try {
			await video.play();
		} catch (error) {
			// no catch
		}
	}
}
