/**
 * @param {HTMLVideoElement} element
 */
export function Autoplay(element) {
	if (!(element instanceof HTMLVideoElement)) {
		return;
	}

	let reducedMotionNoPref = window.matchMedia(
		'(prefers-reduced-motion: no-preference)',
	);

	if (reducedMotionNoPref.matches) {
		play();
	}

	function play() {
		try {
			element.play();
		} catch (error) {
			// no catch
		}
	}

	reducedMotionNoPref.addEventListener('change', () => {
		reducedMotionNoPref.matches ? play() : element.pause();
	});
}
